export const getLasVegasConventionCenterMapData = () => {
    return [
        {
            mapBg: require('@/assets/maps/bg-lvcc-route.jpg'),
            mapLine: 'LasVegasConventionCenterPath',
            venueBg:  require('@/assets/maps/lvcc.png'),
            venue: 'Las Vegas Convention Center',
            distance: '6.0',
            singleLineVenue: false,
            threeLineVenue: true
        }
    ]
}
