<template>
  <div id="LasVegasConventionCenter">
    <WhereWeAreMap :mapData="mapData" />
  </div>
</template>

<script>
import WhereWeAreMap from '@/components/global/WhereWeAreMap'
import { getLasVegasConventionCenterMapData } from "@/data/sectionData/whereWeAreData/lasVegasConventionCenterMapData"

export default {
  name: "LasVegasConventionCenter",
  components: {
    WhereWeAreMap
  },
  data() {
    return {
      mapData: getLasVegasConventionCenterMapData()
    }
  }
}
</script>

<style lang="scss scoped">

</style>